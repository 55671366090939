import { IntelligenceVariant } from './types'
import { features } from '~/lib/feature-flags'

export const getIntelligenceFeatureStatus = (variant: IntelligenceVariant) => {
  const isDisplayEnabled =
    variant === 'accessibility'
      ? features.isEnabled('intelligence-accessibility')
      : features.isEnabled('intelligence-interactivity')

  const isProcessingEnabled =
    variant === 'accessibility'
      ? features.isEnabled('recording-service-discovery-process-accessibility')
      : features.isEnabled('recording-service-discovery-process-interactivity')

  const isTrialExpired = isDisplayEnabled && !isProcessingEnabled
  const isProductEnabled = isDisplayEnabled && isProcessingEnabled

  return {
    isDisplayEnabled,
    isProcessingEnabled,
    isTrialExpired,
    isProductEnabled,
  }
}
