import { configure as configureMobx } from 'mobx'
import gql from 'graphql-tag'
import React, { useEffect, useState, useRef } from 'react'
import CypressSpinner from '@cypress-design/react-spinner'
import { GracefulErrorView } from './GracefullErrorView'
import {
  TestResultStateEnum,
  useTestReplayFrameQuery,
  useSendTestReplayLoadedEventMutation,
  TestReplayRunFragment,
} from '~/graphql-codegen-operations.gen'
import styles from './module.TestReplayModal.scss'
import * as Sentry from '@sentry/browser'
import { features } from '~/lib/feature-flags'
import { useNavigate, NavigateFn } from '@reach/router'

gql`
  query TestReplayFrame($testId: String!) {
    testResult(id: $testId) {
      id
      testId
      titleParts
      state
      testConfig {
        viewportHeight
        viewportWidth
        testIsolation
      }
      capture {
        status
        url
        assetsUrl
        dbByteRange {
          asRangeHeader
        }
        error
      }
    }
  }
`

interface CyReplay {
  mountTestReplay(props: {
    testId: string
    replayUrl: string
    assetsUrl: string | null
    rangeHeader: string | null
    sqlJsUrl: string
    container: HTMLElement
    titleParts: string[]
    testState: TestResultStateEnum
    onDBLoaded: (v: { sizeOfDownload: number }) => void
    testConfig?: {
      viewportHeight: number
      viewportWidth: number
      testIsolation: boolean
    } | null
    hasPaidPlan?: boolean
    navigate: NavigateFn
    features: { canViewCanvas: boolean }
  }): () => void
}

declare global {
  interface Window {
    CyReplay?: CyReplay
    Prism?: any
    __replayAssetMap?: string
  }
}

let replayAssetMap: Record<string, string>
const replayPath = (path: string) => {
  if (!replayAssetMap && window.__replayAssetMap) {
    replayAssetMap = JSON.parse(window.__replayAssetMap)
  }
  const toReturn = replayAssetMap[path]
  if (!toReturn) {
    throw new Error(
      `Expected replay asset path for ${path}, only saw ${Object.keys(
        replayAssetMap
      )}`
    )
  }
  return toReturn
}

function useLoadReplay(js: string, css: string): CyReplay | undefined {
  const [loaded, setLoaded] = useState<CyReplay | Error | undefined>(
    window.CyReplay
  )

  useEffect(() => {
    // @ts-ignore: we need to avoid collisions between
    // test-replay's global Prism and the Cloud's global Prism object
    const DASHBOARD_PRISM = window.Prism

    const t = Sentry?.getCurrentHub()
      ?.getScope()
      ?.getTransaction()
      ?.startChild({
        op: 'fetch',
        description: 'Fetch JS/CSS for Test Replay',
        tags: { jsPath: js, cssPath: css },
      })

    let unmounted = false
    const script = document.createElement('script')
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = replayPath(css)
    script.src = replayPath(js)
    script.async = true
    script.onload = () => {
      if (!unmounted) {
        setLoaded(window.CyReplay ?? new Error('Missing CyReplay'))

        const REPLAY_PRISM = window.Prism

        if (window.CyReplay && REPLAY_PRISM && DASHBOARD_PRISM) {
          window.Prism = new Proxy(REPLAY_PRISM, {
            get(target, prop, receiver) {
              if (prop === 'plugins') {
                // We merge the plugins of the two Prism instances
                // so that any logic bound to the dashboard's Prism instance
                // continues to execute as expected.
                //
                // The line-highlight plugin utilized by the dashboard
                // executes on resize events and will throw if access to
                // to the plugin is lost.
                return {
                  ...DASHBOARD_PRISM.plugins,
                  ...REPLAY_PRISM.plugins,
                }
              }

              return Reflect.get(target, prop, receiver)
            },
          })
        }

        t?.finish()
      } else {
        window.CyReplay = undefined
      }
    }
    script.onerror = (e) => {
      if (!unmounted) {
        t?.setStatus('unavailable')
        t?.finish()
        setLoaded(
          typeof e === 'string'
            ? new Error(e)
            : new Error('Error Loading Asset')
        )
      }
    }
    document.body.appendChild(link)
    document.body.appendChild(script)
    return () => {
      unmounted = true
      document.body.removeChild(link)
      document.body.removeChild(script)
      window.CyReplay = undefined
      // Reset the global for Cloud when closing out
      // or returning from Test-replay
      window.Prism = DASHBOARD_PRISM
    }
  }, [js, css])

  if (loaded instanceof Error) {
    throw loaded
  }

  return loaded
}

/**
 * WARNING: Ensure that any props added here are able
 * to be memoized as to prevent the app polling
 * from causing reloads
 */
interface TestReplayFrameProps {
  testUuid: string
  orgId: TestReplayRunFragment['project']['organizationInfo']['id']
  buildId: TestReplayRunFragment['id']
  projectId: TestReplayRunFragment['project']['id']
  onDBLoaded: (v: { sizeOfDownload: number }) => void
  hasPaidPlan?: boolean
}

interface MountTestReplayProps extends TestReplayFrameProps {
  CyReplay: CyReplay
  onDBLoaded: (v: { sizeOfDownload: number }) => void
}

const MountTestReplay: React.FC<MountTestReplayProps> = (props) => {
  const {
    CyReplay,
    onDBLoaded,
    hasPaidPlan,
    orgId,
    buildId,
    projectId,
    testUuid,
  } = props
  const navigate = useNavigate()
  const mountNode = useRef<HTMLDivElement | null>(null)
  const { data, startPolling, stopPolling } = useTestReplayFrameQuery({
    variables: {
      testId: props.testUuid,
    },
  })
  const {
    capture,
    testId,
    titleParts = [],
    state = 'PASSED',
    testConfig,
  } = data?.testResult ?? {}
  const captureUrl = capture?.url
  const rangeHeader = capture?.dbByteRange?.asRangeHeader ?? null
  const assetsUrl = capture?.assetsUrl ?? null
  const captureStatus = capture?.status
  const captureDataIsProcessing =
    captureStatus && ['PENDING', 'AWAITING_UPLOAD'].includes(captureStatus)

  const shouldPollForProcessing = captureDataIsProcessing
  const shouldMountReplay = captureUrl && testId && !captureDataIsProcessing
  const showGracefulError =
    data && !shouldMountReplay && !shouldPollForProcessing

  useEffect(() => {
    if (shouldPollForProcessing) {
      startPolling(20000)

      return stopPolling
    }

    return undefined
  }, [shouldPollForProcessing, startPolling, stopPolling])

  const [sendTestReplayLoadedEvent] = useSendTestReplayLoadedEventMutation()

  useEffect(() => {
    if (!shouldMountReplay || !mountNode.current) {
      return
    }
    // See https://github.com/cypress-io/cypress/blob/bc6e9e49187aea3a8e021c39b5faee1059f16cf7/packages/driver/cypress/support/utils.ts#L45-L58
    configureMobx({ enforceActions: 'never' })

    sendTestReplayLoadedEvent({
      variables: {
        input: {
          orgId,
          projectId,
          buildId,
          testResultId: testUuid,
        },
      },
    })

    const cleanup = CyReplay.mountTestReplay({
      testId,
      replayUrl: captureUrl,
      rangeHeader,
      assetsUrl,
      container: mountNode.current,
      titleParts,
      testState: state,
      testConfig,
      onDBLoaded,
      hasPaidPlan,
      navigate,

      // We want to serve sql.js wasm code from our own server
      // but this flag allows us to quickly switch to serving from
      // sql.js.org in case there are any issues with our setup:
      sqlJsUrl: features.isEnabled('sql-js-local')
        ? `${window.location.origin}/sql-js/dist/`
        : 'https://cdnjs.cloudflare.com/ajax/libs/sql.js/1.8.0/',
      features: {
        canViewCanvas: features.isEnabled('test-replay-canvas', { projectId }),
      },
    })

    return () => {
      cleanup()
      configureMobx({ enforceActions: 'observed' })
    }
  }, [
    shouldMountReplay,
    captureUrl,
    assetsUrl,
    testId,
    CyReplay,
    titleParts,
    state,
    testConfig,
    onDBLoaded,
    hasPaidPlan,
    sendTestReplayLoadedEvent,
    testUuid,
    rangeHeader,
    orgId,
    projectId,
    buildId,
    navigate,
  ])
  let children
  if (shouldPollForProcessing) {
    children = (
      <div
        className={styles['test-replay-modal--processing-content']}
        data-cy="test-replay-modal-processing"
      >
        <CypressSpinner />
        <div className={styles.heading}>Test Replay is processing</div>
        <div className={styles.details}>
          Test Replay will be available as soon as processing is completed.
        </div>
      </div>
    )
  } else if (showGracefulError) {
    children = (
      <GracefulErrorView hasPaidPlan={hasPaidPlan} errorType={capture?.error} />
    )
  }
  // subtracts modal header height:
  return (
    <div ref={mountNode} style={{ height: 'calc(100% - 64px)' }}>
      {children}
    </div>
  )
}

function TestReplayFrame_Unstable(props: TestReplayFrameProps) {
  const CyReplay = useLoadReplay(
    // These are mapped to the hashed script outputs available
    // in window.__replayAssetMap in useLoadReplay
    '/test-replay-assets/index.js',
    '/test-replay-assets/index.css'
  )
  if (!CyReplay) {
    return null
  }
  return <MountTestReplay CyReplay={CyReplay} {...props} />
}

// Prevent parent rerenders from
// restarting testReplay unnecessarily:
export const TestReplayFrame = React.memo(TestReplayFrame_Unstable)

export default TestReplayFrame
