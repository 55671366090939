import React from 'react'
import TestReplayFrame from './TestReplayFrame'
import Button from '@cypress-design/react-button'
import { MainHeader, MainHeaderSpec } from '~/common/test-replay/MainHeader'
import { IconActionDeleteMedium } from '@cypress-design/react-icon'
import { Modal } from '@frontend/design-system/src/'
import styles from './module.TestReplayModal.scss'
import * as Sentry from '@sentry/browser'
import { FeedbackButton } from '~/common/feedback-button/FeedbackButton'
import { TestReplayRunFragment } from '~/graphql-codegen-operations.gen'

const TestReplayHeader: React.FC<{
  onHide: () => void
  run: TestReplayRunFragment
  spec: MainHeaderSpec
}> = ({ onHide, run, spec }) => (
  <div className={styles['header-control-container']}>
    <MainHeader run={run} spec={spec} />
    <div className={styles['action-buttons']}>
      <FeedbackButton
        url="https://on.cypress.io/report-issue-test-replay"
        organizationId={run.project.organizationInfo.id}
        aria-label="Report an issue"
      >
        Report an issue
      </FeedbackButton>
      <Button
        size="32"
        onClick={onHide}
        variant="outline-dark"
        className={styles['close-button']}
        data-cy="close-replay-button"
      >
        <IconActionDeleteMedium
          className={styles['icon']}
          strokeColor="gray-500"
        />
      </Button>
    </div>
  </div>
)

const createTransaction = (testUuid: string, status: string) => {
  const TRScope = Sentry.getCurrentHub().pushScope()
  const t = Sentry.startTransaction({
    op: 'loading',
    name: 'Test Replay Loading',
    description: 'Time waiting for Test Replay to display',
  })
  TRScope.setTags({
    scope: 'TRScope',
    testUuid,
    runStatus: status,
  })
  TRScope.setSpan(t)
  return t
}

export const TestReplayModal: React.FC<{
  testUuid: string
  onHide: () => void
  run: TestReplayRunFragment
  spec: MainHeaderSpec
  hasPaidPlan: boolean
}> = ({ testUuid, onHide, run, spec, hasPaidPlan }) => {
  const [transaction] = React.useState(() =>
    createTransaction(testUuid, run.status)
  )
  // If the transaction is still active when unmounting the component
  React.useEffect(() => {
    return () => {
      if (!transaction.endTimestamp) {
        transaction.setStatus('aborted')
        transaction.finish()
      }
      Sentry.getCurrentHub().popScope()
    }
  }, [transaction])

  const onDBLoaded = React.useCallback(
    (v: { sizeOfDownload: number }) => {
      transaction.setMeasurement('sizeOfDownload', v.sizeOfDownload, 'byte')
      transaction.setStatus('ok')
      transaction.finish()
    },
    [transaction]
  )

  return (
    // Modal onHide is for outside clicking and ESC key
    <Modal show onHide={onHide} className={styles['test-replay-modal']}>
      {/* Header onHide is for the close button */}
      <TestReplayHeader onHide={onHide} run={run} spec={spec} />
      <TestReplayFrame
        hasPaidPlan={hasPaidPlan}
        onDBLoaded={onDBLoaded}
        testUuid={testUuid}
        orgId={run.project.organizationInfo.id}
        projectId={run.project.id}
        buildId={run.id}
      />
    </Modal>
  )
}
