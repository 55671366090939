import _ from 'lodash'

export type BigintInstanceSourceTable =
  (typeof BIGINT_INSTANCES_SOURCE_TABLES)[number]

export const BIGINT_INSTANCES_SOURCE_TABLES = [
  'cloud_assets',
  'instances',
  'test_result_analytics',
  'test_results_new',
  'test_result_attempts',
  'instance_details',
  'instance_stdout',
  'screencasts',
  'screenshots',
  'test_result_attempt_errors',
  'test_result_errors',
  'protocol_instance_raw',
  'protocol_test_data',
] as const

export type BigintInstanceTargetTable =
  (typeof BIGINT_INSTANCES_TARGET_TABLES)[number]

export const BIGINT_INSTANCES_TARGET_TABLES =
  BIGINT_INSTANCES_SOURCE_TABLES.map((s) => `bigint_${s}` as const)

export const BIGINT_INSTANCES_SOURCE_TABLES_CAMEL =
  BIGINT_INSTANCES_SOURCE_TABLES.map(_.camelCase)

/**
 * Used to determine which table we should be targeting, based on the value of instance_id
 */
export function isBigint(val: number) {
  return val > 2147483647
}

/**
 * Used to determine which table we should be targeting, based on the value of the
 * test result id
 */
export function isBigintTestResult(val: number) {
  return val >= 9000000000
}
