import React from 'react'
import { IconStatusFailedSolid } from '@cypress-design/react-icon'
import Button from '@cypress-design/react-button'
import styles from './module.GroupList.scss'
import { useOrganizationOnboardingUpdateMutation } from '../graphql-codegen-operations.gen'

type SkipButtonProps = {
  orgId: string
  field: string
  isStepChecked: boolean
}

export const SkipButton: React.FunctionComponent<SkipButtonProps> = ({
  orgId,
  field,
  isStepChecked,
}) => {
  const [OrganizationOnboardingUpdate] =
    useOrganizationOnboardingUpdateMutation({
      variables: {
        input: {
          orgId,
          field,
          status: 'SKIPPED',
        },
      },
    })

  if (isStepChecked) {
    return null
  }

  return (
    <Button
      variant="white"
      className={styles.skip}
      onClick={() => OrganizationOnboardingUpdate()}
      data-cy="onboarding-skip"
    >
      <IconStatusFailedSolid className="mr-2" />
      Skip
    </Button>
  )
}
